import {
  type ErrorResponse,
  isRouteErrorResponse,
  useParams,
  useRouteLoaderData,
  useNavigate,
  Form,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { getErrorMessage } from "#app/utils/misc.tsx";
import { Icon } from "#app/components/ui/icon.tsx";
import React from "react";

type StatusHandler = (info: {
  error: ErrorResponse;
  params: Record<string, string | undefined>;
}) => JSX.Element | null;

export function GeneralErrorBoundary({
  defaultStatusHandler = ({ error }) => (
    <p>
      {error.status} {error.data}
    </p>
  ),
  statusHandlers,
  unexpectedErrorHandler = (error) => <p>{getErrorMessage(error)}</p>,
}: {
  defaultStatusHandler?: StatusHandler;
  statusHandlers?: Record<number, StatusHandler>;
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
}) {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  const params = useParams();

  if (typeof document !== "undefined") {
    console.error(error);
  }

  if (error.status === 401) {
    return <UnauthorisedError />;
  }

  if (error.status === 500) {
    return <GeneralError />;
  }

  return (
    <div className="container flex items-center justify-center p-20 text-h2">
      {isRouteErrorResponse(error)
        ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
            error,
            params,
          })
        : unexpectedErrorHandler(error)}
    </div>
  );
}

import { Button } from "#app/components/ui/button";

function UnauthorisedError() {
  return (
    <div className="h-svh">
      <div className="m-auto flex h-full w-full flex-col items-center justify-center gap-2">
        <h1 className="text-[7rem] font-bold leading-tight">401</h1>
        <span className="font-medium">
          Oops! You don't have permission to access this page.
        </span>
        <p className="text-center text-muted-foreground">
          It looks like you tried to access a resource that requires proper
          authentication. <br />
          Please log in with the appropriate credentials.
        </p>
        <div className="mt-6 flex gap-4">
          <Form method="post" action={`/logout`}>
            <Button
              type="submit"
              className="flex w-full items-center gap-1.5 rounded-md text-sm"
            >
              <Icon name="logout" size="md">
                Back
              </Icon>
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

import { cn } from "#app/utils/misc.tsx";
import { type loader } from "#app/root.tsx";

interface GeneralErrorProps extends React.HTMLAttributes<HTMLDivElement> {
  minimal?: boolean;
}

export default function GeneralError({
  className,
  minimal = false,
}: GeneralErrorProps) {
  const { scope } = useRouteLoaderData("routes/dashboard+/route");
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(".", { replace: true });
  }, [scope]);

  return (
    <div className={cn("h-[85vh] w-full", className)}>
      <div className="m-auto flex h-full w-full flex-col items-center justify-center gap-2">
        {!minimal && (
          <h1 className="text-[7rem] font-bold leading-tight">500</h1>
        )}
        <span className="font-medium">Oops! Something went wrong {`:')`}</span>
        <p className="text-center text-muted-foreground">
          We apologize for the inconvenience. <br /> Please try again later.
        </p>
        {!minimal && (
          <div className="mt-6 flex gap-4">
            <Button
              variant="outline"
              onClick={() => navigate(-2, { replace: true })}
            >
              Go Back
            </Button>
            <Button onClick={() => navigate("/")}>Back to Dashboard</Button>
          </div>
        )}
      </div>
    </div>
  );
}
